import { useEffect, useState } from 'react';
import './HomePage.scss';
import moment from 'moment';
import event1Image from '../../assets/img/event-1.webp';
import event2Image from '../../assets/img/event-2.webp';
import creditsIcon from '../../assets/img/premium-credits-icon.png';
import axios from 'axios';
import { Link } from 'react-router-dom';

const HomePage = (props) => {
  const [event1TimeAgo, setEvent1TimeAgo] = useState('');
  const [event2DaysLeft, setEvent2DaysLeft] = useState('');
  const [isEvent2WindowOpen, setIsEvent2WindowOpen] = useState(false);
  const [agentName, setAgentName] = useState('');
  const [email, setEmail] = useState('');
  const [joinedEvents, setJoinedEvents] = useState([]);

  useEffect(() => {
    let je = localStorage.getItem('joined-events');
      
    if (je) {
      je = JSON.parse(je);
    } else {
      je = [];
    }

    setJoinedEvents(je);

    const event2EndDate = moment('2024-5-31 23:59:59');
    setEvent2DaysLeft(`${event2EndDate.diff(moment(), 'day')}`);

    const event1EndDate = moment('2024-4-27 18:00:00');
    setEvent1TimeAgo(`${event1EndDate.fromNow()}`);
  }, []);

  function register() {
    if (agentName.trim().length > 2 && email.trim().length > 5) {
      // axios.post(`http://localhost/fabledwarlords/register.php`, {
      axios.post(`https://fabledwarlords.com/register.php`, {
        agentName,
        email,
        eventId: 2,
      }).then((response) => {
        if (response.data === 1) {
          setIsEvent2WindowOpen(false);
          
          let je = localStorage.getItem('joined-events');
          
          if (je) {
            je = JSON.parse(je);
          } else {
            je = [];
          }

          je.push(2);

          setJoinedEvents(je);
          localStorage.setItem('joined-events', JSON.stringify(je));
        }
      });
    }
  }

  return (
    <div className="HomePage view">
      <div className="event">
        <div className="header">
          <span>Event Ended</span>
          <span>{event1TimeAgo}</span>
        </div>

        <div className="image">
          <img src={event1Image} />
        </div>

        <div className="content">
          <span className="type">Clan Event</span>
          <span className="name">First In</span>
          <div>
            <span className="description">The first member joining our clan will win a prize.</span>
            <span className="prize">1000 + 50 Premium Credits <img src={creditsIcon} /></span>
            <span className="status">ENDED</span>
          </div>
        </div>
      </div>

      <div className="event">
        <div className="header">
          <span>Ongoing <Link to="/roster">View Leaderboard</Link></span>
          <span>{event2DaysLeft} days left</span>
        </div>

        <div className="image">
          <img src={event2Image} />
        </div>

        <div className="content">
          <span className="type">Clan Event</span>
          <span className="name">Welcome</span>
          <div>
            <span className="description">The member with the highest progress in level will win a prize.</span>
            <span className="prize">2000 + 250 Premium Credits <img src={creditsIcon} /></span>
          </div>
        </div>

        <button className="btn-join" onClick={() => !joinedEvents.includes(2) && setIsEvent2WindowOpen(true)}>
          {
            joinedEvents.includes(2)
              ? <span>You have joined the event</span>
              : <span>Join the Event</span>
          }
        </button>
      </div>

      <div className={`event-2-info-window ${isEvent2WindowOpen ? 'open' : ''}`}>
        <div className="content">
          <button className="btn-close" onClick={() => setIsEvent2WindowOpen(false)}>&times;</button>
          <span>1. Join the clan</span>
          <span>2. Register email to contact you for the prize</span>
          <span>3. Level up until the end of May 2024</span>
          <span style={{ fontFamily: 'bold' }}>Good Luck!</span>

          <div className="form">
            <input
              type="text"
              placeholder="Agent name"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
            />
            
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <button className="btn-join" onClick={register}>Join the Event</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
