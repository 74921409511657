import './Header.scss';
import logo from '../../assets/img/logo.png';
import psIcon from '../../assets/img/playstation-icon.webp';

const Header = (props) => {
  return (
    <div className="Header">
      <div className="brand">
        <div className="logo">
          <img src={logo} />
        </div>
        <span className="name">Fabled Warlords</span>
        <span className="moto">Division Elite</span>
        <img src={psIcon} height="30" />
      </div>

      <span className="message">Message <b>Holycious3</b> to join us</span>
    </div>
  );
}

export default Header;
