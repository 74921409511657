import { useEffect, useState } from 'react';
import './Roster.scss';
import axios from 'axios';
import moment from 'moment';

const Roster = (props) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    // axios.get(`http://localhost/fabledwarlords/get-roster.php`).then((response) => {
    axios.get(`https://fabledwarlords.com/get-roster.php`).then((response) => {
      const m = response.data;

      m.forEach((member) => {
        member.joinedAgo = moment(member.joined_at).fromNow();
        const hoursAsMember = moment().diff(moment(member.joined_at), 'hours');
        member.levelsPerHour = ((member.level - member.level_joined) / hoursAsMember).toFixed(2);
      });

      m.sort((a, b) => {
        if (a.levelsPerHour > b.levelsPerHour) {
          return -1;
        } else {
          return 1;
        }
      })

      setMembers(m);
    });
  }, []);

  return (
    <div className="Roster view">
      <div className="member-list">
        {
          members.map((player, i) => {
            return (
              <div className="member" key={i}>
                <div className="header">
                  <span className="name">{player.name}</span>
                  <span className="level">{player.level}</span>
                </div>

                <div className="details">
                  <span>Joined {player.joinedAgo}</span>
                  <span>{player.levelsPerHour} levels / hour</span>
                </div>

                {
                  (player.id == 13 || player.id == 14) && <span style={{ marginTop: '10px', color: 'red', fontSize: '11px' }}>Not eligible for the prize</span>
                }
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default Roster;
