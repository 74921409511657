import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import './overrides.scss';
import HomePage from './components/home-page/HomePage';
import Header from './components/header/Header';
import Roster from './components/roster/Roster';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Header></Header>

          <main>
            <Routes>
              <Route exact path="/" element={<HomePage />}></Route>
              <Route exact path="/roster" element={<Roster />}></Route>
            </Routes>
          </main>
        </BrowserRouter>     
    </div>
  );
}

export default App;
